import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IReview } from '@guider-global/shared-types';
import { Stack, Text, theme, TextStack, Switch } from '@guider-global/ui';
import { Star, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, CircularProgress, useTheme } from '@mui/material';

import { format } from 'date-fns';
import { useLocalization, useReviews } from 'hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';
import { deepMerge } from 'utils';

interface ReviewContainerProps {
  review: IReview;
}

export function ReviewContainer({ review }: ReviewContainerProps) {
  // State
  const [approved, setApproved] = useState<boolean>(review.isApproved ?? false);

  // Hooks
  const organizationTheme = useTheme();
  const palette = deepMerge(theme.appTheme.palette, organizationTheme.palette);
  const dispatch = useDispatch();

  // Base Language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const reviewsText = baseLanguage?.settings?.reviews;

  // Reviews
  const { reqReviews, isLoadingReviews } = useReviews({});

  async function handleApproval() {
    if (!review) return;
    setApproved(!approved);
    try {
      await reqReviews({
        method: 'PATCH',
        url: `/reviews/${review.otpSurveyId}`,
        data: {
          isApproved: !approved,
        },
      });
    } catch (err: unknown) {
      setApproved(approved);
      dispatch(
        showAppAlert({
          severity: 'error',
          message: 'Error updating review',
          timeout: 3000,
        }),
      );
    }
  }

  return (
    <Box
      key={`${review.otpSurveyId}`}
      display={'flex'}
      flexDirection={'column'}
      p={2.5}
      gap={1.5}
      borderRadius={'10px'}
      bgcolor={'#F7F7F7'}
      sx={{
        border: `1px solid #E0E0E0`,
      }}
    >
      <Stack
        direction={'row'}
        gap={0}
        spacing={0}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box>
          {Array.from({ length: review.score }, () => (
            <Star color="warning" />
          ))}
        </Box>
        <Stack direction={'row'} gap={0.2} spacing={0} alignItems={'center'}>
          <Text
            text={
              approved ? reviewsText?.visible_label : reviewsText?.hidden_label
            }
            variant="h6"
            color={approved ? 'green' : 'text.secondary'}
            display={isLoadingReviews ? 'none' : ''}
          />
          <Switch
            checked={approved}
            color={palette.success.main}
            onChange={() => handleApproval()}
            disabled={isLoadingReviews}
          />
          <Box display={isLoadingReviews ? 'none' : ''}>
            {approved ? (
              <Visibility color="success" sx={{ mt: 0.5 }} />
            ) : (
              <VisibilityOff color="disabled" sx={{ mt: 0.5 }} />
            )}
          </Box>
          {isLoadingReviews && (
            <CircularProgress
              size={15}
              color={'info'}
              sx={{
                animationTimingFunction: 'linear',
                animationDuration: '20s',
              }}
            />
          )}
        </Stack>
      </Stack>
      <TextStack
        size={'xs'}
        spacing={2}
        heading={{
          variant: 'body1Secondary',
          text: `${review.firstName} ${review.lastName}${
            review.jobTitle ? ', ' + review.jobTitle : ''
          }`,
        }}
        subtitles={[
          { variant: 'body1', text: review.text },
          {
            variant: 'body2Secondary',
            text: `${format(new Date(review.date), 'MMMM yyyy')}` ?? '',
          },
        ]}
      />
    </Box>
  );
}

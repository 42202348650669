import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { DashboardNotificationsContext } from '../DashboardWrapper';
import { ScheduleSessionView } from 'views';
import { useLocalization } from 'hooks';
import { getRecipients } from 'utils/getRelationshipRecipients';
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useUsers } from '@guider-global/front-end-hooks';

export function ScheduleSessionContainer() {
  const navigate = useNavigate();
  const { getProfileImage } = useProfileImage();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  const {
    programName,
    relationshipId,
    isGuide,
    isGroupProgram,
    relationship,
    profile,
  } = useContext(DashboardNotificationsContext);

  // User
  const { users } = useUsers({});
  const user = users?.at(0);
  const userId = user?.id;

  // Get images
  const isUserGuide = relationship?.guideProfiles?.some(
    (profile) => profile.userId === userId,
  );

  const [otherPartyProfile] = getRecipients({
    role: isUserGuide ? 'guide' : 'trainee',
    relationship,
    userId,
  });

  const otherUserImage =
    getProfileImage({
      profilePicture: otherPartyProfile?.picture,
      userPicture: otherPartyProfile?.userPicture,
    }) ?? '';

  const userImage =
    getProfileImage({
      profilePicture: profile?.picture,
      userPicture: user?.picture,
    }) ?? '';

  const { baseLanguage } = useBaseLanguage({ localeCode });

  const viewProps = useMemo(() => {
    let title,
      description,
      primaryButtonLabel,
      primaryButtonOnClick = () => {};

    if (isGroupProgram) {
      if (isGuide) {
        title =
          baseLanguage?.dashboard?.onboarding?.steps?.group_program
            ?.guide_schedule_group_session?.title;
        description =
          baseLanguage?.dashboard?.onboarding?.steps?.group_program
            ?.guide_schedule_group_session?.description;
        primaryButtonLabel =
          baseLanguage?.dashboard?.onboarding?.steps?.group_program
            ?.guide_schedule_group_session?.schedule_group_session_button_label;
        primaryButtonOnClick = () =>
          navigate(`/relationships/${relationshipId}/sessions/create`);
      } else {
        title =
          baseLanguage?.dashboard?.onboarding?.steps?.group_program
            ?.trainee_wait_for_session?.title;
        description =
          baseLanguage?.dashboard?.onboarding?.steps?.group_program
            ?.trainee_wait_for_session?.description;
        primaryButtonLabel =
          baseLanguage?.dashboard?.onboarding?.steps?.group_program
            ?.trainee_wait_for_session?.view_group_button_label;
        primaryButtonOnClick = () =>
          navigate(`/relationships/${relationshipId}`);
      }
    } else {
      title =
        baseLanguage?.dashboard?.onboarding?.steps?.individual_program
          ?.schedule_a_session?.title;
      description =
        baseLanguage?.dashboard?.onboarding?.steps?.individual_program
          ?.schedule_a_session?.description;
      primaryButtonLabel =
        baseLanguage?.dashboard?.onboarding?.steps?.individual_program
          ?.schedule_a_session?.schedule_a_session_button_label;
      primaryButtonOnClick = () =>
        navigate(`/relationships/${relationshipId}/sessions/create`);
    }

    return {
      title,
      description,
      primaryButtonLabel,
      primaryButtonOnClick,
    };
  }, [baseLanguage, isGroupProgram, isGuide, navigate, relationshipId]);

  return (
    <ScheduleSessionView
      otherUserAvatar={otherUserImage ?? ''}
      userAvatar={userImage ?? ''}
      programLabel={programName}
      heading={viewProps?.title}
      description={viewProps?.description}
      primaryButtonLabel={viewProps?.primaryButtonLabel}
      primaryButtonOnClick={viewProps?.primaryButtonOnClick}
    />
  );
}
